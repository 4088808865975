import { clsx } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  RegrelloButton,
  RegrelloIcon,
  RegrelloIconName,
  RegrelloIconStyler,
  RegrelloTypography,
} from "@regrello/ui-core";
import { memo, useCallback } from "react";
import { toast } from "sonner";

import { RegrelloBaseToastProps } from "./_internal/RegrelloBaseToast";
export interface RegrelloToastProps extends Omit<RegrelloBaseToastProps, "onClose"> {
  toastId: string | number;
}

export const RegrelloToast = memo<RegrelloToastProps>(function RegrelloToastFn({
  content,
  title,
  icon,
  intent,
  toastId,
}: RegrelloToastProps) {
  const renderStartIcon = useCallback((iconInternal: RegrelloIconName | JSX.Element) => {
    return typeof iconInternal === "string" ? (
      <RegrelloIcon iconName={iconInternal} size="medium" />
    ) : (
      <RegrelloIconStyler size="medium">{iconInternal}</RegrelloIconStyler>
    );
  }, []);

  const renderContent = useCallback(() => {
    return typeof content === "string" ? (
      <RegrelloTypography muted={true}>{content}</RegrelloTypography>
    ) : (
      <div className="text-textMuted">{content}</div>
    );
  }, [content]);

  return (
    <div
      className="w-full max-w-100 flex bg-background rounded border shadow-md"
      data-testid={DataTestIds.TOAST_MESSAGE}
    >
      <div
        className={clsx("self-stretch w-1.5 my-1 ml-1 rounded-circular", {
          "bg-danger-icon": intent === "danger",
          "bg-primary-icon": intent === "info",
          "bg-success-icon": intent === "success",
          "bg-warning-icon": intent === "warning",
        })}
      />
      <div className="w-full flex items-center pl-4 pr-2">
        {icon != null && (
          <div
            className={clsx("self-start mt-2.5", {
              "text-danger-icon": intent === "danger",
              "text-primary-icon": intent === "info",
              "text-success-icon": intent === "success",
              "text-warning-icon": intent === "warning",
            })}
          >
            {renderStartIcon(icon)}
          </div>
        )}
        <div className="flex-1 pl-4 pr-8 py-3">
          {title != null && <RegrelloTypography>{title}</RegrelloTypography>}
          <div>{renderContent()}</div>
        </div>
        <RegrelloButton
          dataTestId={DataTestIds.TOAST_MESSAGE_CLOSE_BUTTON}
          iconOnly={true}
          onClick={() => toast.dismiss(toastId)}
          shape="circle"
          startIcon="close"
          variant="ghost"
        />
      </div>
    </div>
  );
});
