import { EMPTY_ARRAY, getEmailDomain, isValidEmail, KeyNames, useConfirmationDialog } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import {
  AccessRole,
  AccessRoleName,
  AccessRoleScope,
  AccessRoleUserScope,
  RoleFields,
  useAccessRolesQueryLazyQuery,
  useCurrentTenantQuery,
  useIsEmailUniqueQueryLazyQuery,
  UserAccessLevel,
  useRolesQuery,
} from "@regrello/graphql-api";
import { RegrelloConfirmationDialog, RegrelloIntentV2, RegrelloTypography } from "@regrello/ui-core";
import {
  AreYouSure,
  Emails,
  External,
  FormErrorBulkEmailAlreadyInUse,
  FormErrorBulkEmailInvalid,
  FormErrorEmailAlreadyInUse,
  FormErrorEmailExternalDomain,
  FormErrorEmailInvalid,
  FormHelperTextInviteUserRoles,
  FormHelperTextInviteUserType,
  FormHelperTextMultipleEmailInput,
  FormHelperTextMultipleEmailInputV2,
  FormLabelEmail,
  FormLabelName,
  Internal,
  InternalToExternalChangeWarning,
  OutOfOffice,
  PermissionLevel,
  Roles,
  SetAsAway,
  Theme,
  ThemeDark,
  ThemeLight,
  ThemeSystem,
  Type,
} from "@regrello/ui-strings";
import orderBy from "lodash/orderBy";
import React, { useCallback, useEffect, useMemo } from "react";
import { SetValueConfig, UseFormReturn, useWatch } from "react-hook-form";

import { OutOfOfficeEventFormFields, RegrelloOutOfOfficeEventForm } from "./RegrelloOutOfOfficeEventForm";
import { ValidationRules } from "../../../../../constants/globalConstants";
import { FeatureFlagService } from "../../../../../services/FeatureFlagService";
import { UserTheme } from "../../../../../state/applicationState";
import { RegrelloFormValidationReason } from "../../../../../utils/formUtils";
import { useErrorHandler } from "../../../../../utils/hooks/useErrorHandler";
import { useMemoizedAsyncLoadedValue } from "../../../../../utils/hooks/useMemoizedAsyncLoadedValue";
import { useUser } from "../../../../app/authentication/userContextUtils";
import { useRelativePermission } from "../../../../app/authorization/useRelativePermission";
import {
  RegrelloControlledFormFieldMultiSelect,
  RegrelloControlledFormFieldRadioGroup,
  RegrelloControlledFormFieldSelect,
  RegrelloControlledFormFieldTagInput,
} from "../../../../molecules/formFields/controlled/regrelloControlledFormFields";
import { RegrelloControlledFormFieldText } from "../../../../molecules/formFields/controlled/RegrelloControlledFormFieldText";
import { RegrelloFormFieldSwitch } from "../../../../molecules/formFields/RegrelloFormFieldSwitch";

// (zstanik): Wide enough for the static label "Permission level" to fit on one line.
const LABEL_WIDTH = 120;

const THEME_RADIO_OPTIONS: Array<{ label: string; value: UserTheme }> = [
  { label: ThemeLight, value: "light" },
  { label: ThemeDark, value: "dark" },
  { label: ThemeSystem, value: "system" },
];

export enum FrontendUserAccessLevel {
  ADMIN = "ADMIN",
  NON_ADMIN_INTERNAL = "NON_ADMIN_INTERNAL",
  NON_ADMIN_EXTERNAL = "NON_ADMIN_EXTERNAL",
}

export interface UserFormFields {
  name: string;
  emailContext:
    | {
        type: "single";
        email: string;
      }
    | {
        type: "multiple";
        emails: string[];
      };
  accessLevel: FrontendUserAccessLevel;
  accessLevelPermissionsV2: UserAccessLevel;
  accessRole: AccessRole | undefined;
  /** Only used by permissions V2 currently. */
  permissionsV2Roles?: RoleFields[];
  outOfOffice: OutOfOfficeEventFormFields | undefined;
  userTheme?: UserTheme;
}

export interface RegrelloUserFormProps {
  form: UseFormReturn<UserFormFields>;

  /**
   * Provide `true` when the form is for the `currentUser` in order to prevent specific self-actions
   * from being taken (e.g., removing own admin role).
   *
   * @default false
   */
  isUserEditingThemselves?: boolean;

  /**
   * Whether the name field is disabled.
   *
   * @default false
   */
  isNameDisabled?: boolean;

  /**
   * Whether the user's email address can be changed.
   *
   * @default false
   */
  isEmailEditable?: boolean;

  /**
   * Hide out of office toggle.
   *
   * @default false
   */
  isOooSettingsHidden: boolean;

  setTriedToSubmitWithoutDelegates?: React.Dispatch<React.SetStateAction<boolean>>;
  triedToSubmitWithoutDelegates?: boolean;

  /**
   * Whether the user's type field is disabled.
   *
   * @default false
   */
  isTypeDisabled?: boolean;
}

/**
 * Renders a standard form for collecting information about a user. Can be used in add-user or
 * edit-user dialogs.
 */
export const RegrelloUserForm = React.memo(function RegrelloUserFormFn({
  form,
  isOooSettingsHidden,
  isEmailEditable,
  isNameDisabled,
  isTypeDisabled,
  isUserEditingThemselves = false,
  setTriedToSubmitWithoutDelegates,
  triedToSubmitWithoutDelegates,
}: RegrelloUserFormProps) {
  const { currentUser } = useUser();
  const isOwner = currentUser.accessRole?.name === AccessRoleName.OWNER;
  const isAdmin = currentUser.isAdmin;
  const isExternal =
    currentUser.accessRole?.name === AccessRoleName.EXTERNAL_CAN_VIEW_AND_COMMENT ||
    currentUser.accessRole?.name === AccessRoleName.EXTERNAL_TEAM_ADMIN;
  const [getAccessRolesAsync, accessRolesQueryResult] = useAccessRolesQueryLazyQuery({});
  const { handleError } = useErrorHandler();

  const { data: currentTenant } = useCurrentTenantQuery({
    fetchPolicy: "cache-and-network",
  });

  const { getIsAccessRoleAssignableByCurrentUser } = useRelativePermission();

  useEffect(() => {
    void getAccessRolesAsync({
      variables: {
        scope: AccessRoleScope.TEAM,
      },
    });
  }, [getAccessRolesAsync]);

  const emailContextType = useWatch({ control: form.control, name: "emailContext.type" });

  const outOfOffice = useWatch({ control: form.control, name: "outOfOffice" });

  // (akager): Avoid errors of the form 'Type instantiation is excessively deep and possibly
  // infinite' by explicitly stating the keys instead of reling on the default Path type.
  const formSetValue = form.setValue as <K extends keyof UserFormFields>(
    name: K,
    value: UserFormFields[K],
    options?: SetValueConfig,
  ) => void;

  const onSwitchChange = useCallback(() => {
    if (outOfOffice === undefined) {
      formSetValue("outOfOffice", { startAt: null, endAt: null, delegationSettings: undefined });
    } else {
      formSetValue("outOfOffice", undefined);
      if (setTriedToSubmitWithoutDelegates != null) {
        setTriedToSubmitWithoutDelegates(false);
      }
    }
  }, [outOfOffice, formSetValue, setTriedToSubmitWithoutDelegates]);

  const internalAccessRoles = useMemo(
    () =>
      accessRolesQueryResult.data?.accessRoles.filter(
        (accessRoleInternal) =>
          accessRoleInternal.userScope === AccessRoleUserScope.INTERNAL &&
          getIsAccessRoleAssignableByCurrentUser(accessRoleInternal),
      ) ?? EMPTY_ARRAY,
    [accessRolesQueryResult.data?.accessRoles, getIsAccessRoleAssignableByCurrentUser],
  );
  const externalAccessRoles = useMemo(
    () =>
      accessRolesQueryResult.data?.accessRoles.filter(
        (accessRoleInternal) =>
          accessRoleInternal.userScope === AccessRoleUserScope.EXTERNAL &&
          getIsAccessRoleAssignableByCurrentUser(accessRoleInternal),
      ) ?? EMPTY_ARRAY,
    [accessRolesQueryResult.data?.accessRoles, getIsAccessRoleAssignableByCurrentUser],
  );

  const availableAccessRoles = useMemo(() => {
    if (isOwner || isAdmin || !isExternal) {
      return [...internalAccessRoles, ...externalAccessRoles];
    }
    return externalAccessRoles;
  }, [isOwner, isAdmin, isExternal, internalAccessRoles, externalAccessRoles]);

  const isEmailInUserDomain = useCallback(
    (email: string) => getEmailDomain(email) === getEmailDomain(currentUser.email),
    [currentUser.email],
  );

  const groupBy = useCallback(
    (option: AccessRole) => (option.userScope === AccessRoleUserScope.INTERNAL ? Internal : External),
    [],
  );

  const selectedAccessLevelPermissionsV2 = useWatch({ control: form.control, name: "accessLevelPermissionsV2" });
  const selectedRoles = useWatch({ control: form.control, name: "permissionsV2Roles" });
  const canAssignRoles = currentUser.permissions.canAssignRoles;
  // (wsheehan): We want to hide roles when the current user is inviting someone but does not have
  // access to assign roles.
  const isRolesDropdownVisible =
    canAssignRoles || (selectedRoles != null && selectedRoles.length > 0) || isUserEditingThemselves;

  const isPermissionsV2Enabled = FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01);
  const rolesQueryResult = useRolesQuery({
    variables: {
      input: {},
    },
    fetchPolicy: "no-cache",
    skip: !isPermissionsV2Enabled,
  });

  const asyncPermissionV2Roles = useMemoizedAsyncLoadedValue(rolesQueryResult, (d) => d.roles);

  const permissionV2Roles: RoleFields[] = useMemo(
    () =>
      orderBy(
        (asyncPermissionV2Roles.type !== "loaded" ? EMPTY_ARRAY : asyncPermissionV2Roles.value.roles)
          .map(({ role }) => role)
          .filter((role) => {
            // Always show custom roles, and only show internal vs external system roles based on
            // the selected access level.
            return !role.isSystem || selectedAccessLevelPermissionsV2 === UserAccessLevel.INTERNAL;
          }),
        ["name"],
        ["asc"],
      ),
    [asyncPermissionV2Roles, selectedAccessLevelPermissionsV2],
  );

  const {
    isOpen: isConfirmTypeSwitchDialogOpen,
    open: openConfirmTypeSwitchDialog,
    close: closeConfirmTypeSwitchDialog,
  } = useConfirmationDialog();

  const onConfirmTypeSwitchDialogCancel = useCallback(() => {
    formSetValue("accessLevelPermissionsV2", UserAccessLevel.INTERNAL);
    closeConfirmTypeSwitchDialog();
  }, [closeConfirmTypeSwitchDialog, formSetValue]);

  const onAccessLevelValueChange = useCallback(
    (_: unknown, newValue: UserAccessLevel | null, oldValue: UserAccessLevel | null) => {
      if (
        form.getValues().permissionsV2Roles?.some((r) => r.isSystem) &&
        oldValue === UserAccessLevel.INTERNAL &&
        newValue === UserAccessLevel.EXTERNAL
      ) {
        openConfirmTypeSwitchDialog();
      }
    },
    [form, openConfirmTypeSwitchDialog],
  );

  const onAccessLevelInternalToExternalConfirm = useCallback(() => {
    const newRoles = form.getValues()?.permissionsV2Roles?.filter((r) => !r.isSystem);
    formSetValue("permissionsV2Roles", newRoles);
    closeConfirmTypeSwitchDialog();
  }, [closeConfirmTypeSwitchDialog, form, formSetValue]);

  const getSelectedItemProps = useCallback(
    (option: RoleFields, _index: number) => {
      const isLikelyAdminRole = option.isSystem && option.name === "Admin";
      const isScimRole = option.scimId != null && option.scimId.length > 0;

      return {
        icon: { type: "iconName" as const, iconName: getAccessRoleIcon(option) },
        children: option.name,
        disabled: isScimRole || (isLikelyAdminRole && isUserEditingThemselves),
        intent: RegrelloIntentV2.NEUTRAL,
      };
    },
    [isUserEditingThemselves],
  );

  const getItemDisabled = useCallback(
    (option: RoleFields) => {
      const isLikelyAdminRole = option.isSystem && option.name === "Admin";
      const isScimRole = option.scimId != null && option.scimId.length > 0;
      return isScimRole || (isLikelyAdminRole && isUserEditingThemselves);
    },
    [isUserEditingThemselves],
  );

  const renderItem = useCallback((option: RoleFields) => {
    return {
      key: option.id,
      icon: getAccessRoleIcon(option),
      text: option.name,
    };
  }, []);

  const [getIsEmailUniqueAsync] = useIsEmailUniqueQueryLazyQuery({
    fetchPolicy: "network-only",
    onError: (error) => handleError(error),
  });

  /**
   * Callback that returns `true` if another loaded user is already attached to the provided email.
   * If so, then we shouldn't permit another user to be created with that email, because it would
   * error on the backend.
   */
  const isEmailUnique = useCallback(
    async (email: string) => {
      const result = await getIsEmailUniqueAsync({
        variables: {
          email,
        },
      });

      return result.data?.isEmailUnique.isUnique ?? false;
    },
    [getIsEmailUniqueAsync],
  );

  const validateEmailsAreUnique = useCallback(
    async (emails: string[], validationMessage: string): Promise<undefined | string> => {
      const allUnique = await emails.reduce<Promise<boolean>>(async (accPromise: Promise<boolean>, email: string) => {
        const acc = await accPromise;
        const unique = await isEmailUnique(email);
        return acc && unique;
      }, Promise.resolve(true));

      return allUnique ? undefined : validationMessage;
    },
    [isEmailUnique],
  );

  return (
    <div className="flex flex-col gap-2">
      {emailContextType === "multiple" ? (
        // Emails
        <RegrelloControlledFormFieldTagInput
          autoFocus={false}
          controllerProps={{
            control: form.control,
            name: "emailContext.emails",
            rules: {
              ...ValidationRules.REQUIRED,
              validate: isEmailEditable
                ? {
                    [RegrelloFormValidationReason.PATTERN]: (value) =>
                      value.map((token) => token.trim()).every(isValidEmail)
                        ? !currentTenant?.currentTenant?.permissionAllowNonAdminUserToInviteNonDomain &&
                          currentUser.accessRole?.userScope === AccessRoleUserScope.EXTERNAL
                          ? value.reduce((acc, email) => acc && isEmailInUserDomain(email), true)
                            ? undefined
                            : FormErrorEmailExternalDomain
                          : undefined
                        : FormErrorBulkEmailInvalid,
                    [RegrelloFormValidationReason.UNIQUE]: (value) =>
                      validateEmailsAreUnique(value, FormErrorBulkEmailAlreadyInUse),
                  }
                : undefined,
            },
          }}
          dataTestId={DataTestIds.ADD_USER_DIALOG_FIELD_MULTIPLE_EMAILS}
          delimiters={[KeyNames.COMMA, KeyNames.SPACE, KeyNames.ENTER]}
          disabled={!isEmailEditable}
          helperText={isPermissionsV2Enabled ? FormHelperTextMultipleEmailInputV2 : FormHelperTextMultipleEmailInput}
          isRequiredAsteriskShown={true}
          label={Emails}
          labelWidth={LABEL_WIDTH}
        />
      ) : (
        // Emails
        <>
          <RegrelloControlledFormFieldText
            autoFocus={true}
            controllerProps={{
              control: form.control,
              name: "name",
              rules: ValidationRules.REQUIRED,
            }}
            dataTestId={DataTestIds.ADD_USER_DIALOG_FIELD_NAME}
            disabled={isNameDisabled}
            isRequiredAsteriskShown={true}
            label={FormLabelName}
            labelWidth={LABEL_WIDTH}
          />
          <RegrelloControlledFormFieldText
            controllerProps={{
              control: form.control,
              name: "emailContext.email",
              rules: {
                ...ValidationRules.REQUIRED,
                validate: isEmailEditable
                  ? {
                      [RegrelloFormValidationReason.PATTERN]: (value) =>
                        isValidEmail(value)
                          ? currentUser.accessRole?.userScope === AccessRoleUserScope.EXTERNAL
                            ? isEmailInUserDomain(value)
                              ? undefined
                              : FormErrorEmailExternalDomain
                            : undefined
                          : FormErrorEmailInvalid,
                      [RegrelloFormValidationReason.UNIQUE]: (value) =>
                        validateEmailsAreUnique([value], FormErrorEmailAlreadyInUse),
                    }
                  : undefined,
              },
            }}
            dataTestId={DataTestIds.ADD_USER_DIALOG_FIELD_EMAIL}
            disabled={!isEmailEditable}
            isRequiredAsteriskShown={true}
            label={FormLabelEmail}
            labelWidth={LABEL_WIDTH}
          />
        </>
      )}
      {isPermissionsV2Enabled ? (
        <>
          {/* Type */}
          <RegrelloControlledFormFieldSelect
            controllerProps={{
              control: form.control,
              name: "accessLevelPermissionsV2",
              rules: ValidationRules.REQUIRED,
            }}
            dataTestId={DataTestIds.ADD_USER_DIALOG_FIELD_ACCESS_TYPE}
            disabled={isUserEditingThemselves || isTypeDisabled}
            getOptionLabel={(accessLevel) => (accessLevel === UserAccessLevel.INTERNAL ? Internal : External)}
            helperText={FormHelperTextInviteUserType}
            isFilterable={false}
            isRequiredAsteriskShown={true}
            label={Type}
            labelWidth={LABEL_WIDTH}
            onValueChange={onAccessLevelValueChange}
            options={
              currentUser.accessLevel === UserAccessLevel.EXTERNAL
                ? [UserAccessLevel.EXTERNAL]
                : [UserAccessLevel.INTERNAL, UserAccessLevel.EXTERNAL]
            }
          />

          {/* Roles */}
          {isRolesDropdownVisible && (
            <RegrelloControlledFormFieldMultiSelect
              controllerProps={{
                control: form.control,
                name: "permissionsV2Roles",
              }}
              dataTestId={DataTestIds.ADD_USER_DIALOG_FIELD_ACCESS_CUSTOM_ROLES}
              disabled={!canAssignRoles}
              getItemDisabled={getItemDisabled}
              getSelectedItemProps={getSelectedItemProps}
              helperText={FormHelperTextInviteUserRoles}
              isItemsEqual={(a, b) => a.id === b.id}
              isRequiredAsteriskShown={false}
              itemPredicate={itemPredicate}
              items={permissionV2Roles}
              label={Roles}
              labelWidth={LABEL_WIDTH}
              renderItem={renderItem}
            />
          )}

          <RegrelloConfirmationDialog
            content={InternalToExternalChangeWarning}
            isOpen={isConfirmTypeSwitchDialogOpen}
            onClose={onConfirmTypeSwitchDialogCancel}
            onConfirm={onAccessLevelInternalToExternalConfirm}
            title={AreYouSure}
          />
        </>
      ) : (
        // Permission level
        <RegrelloControlledFormFieldSelect
          controllerProps={{
            control: form.control,
            name: "accessRole",
            rules: {
              ...ValidationRules.REQUIRED,
            },
          }}
          dataTestId={DataTestIds.ADD_USER_DIALOG_FIELD_ACCESS_ROLE}
          disabled={isUserEditingThemselves}
          getOptionLabel={getAccessRoleLabel}
          getOptionValue={getAccessRoleValue}
          groupBy={isOwner || isAdmin || !isExternal ? groupBy : undefined}
          isFilterable={false}
          isRequiredAsteriskShown={true}
          label={PermissionLevel}
          labelWidth={LABEL_WIDTH}
          options={availableAccessRoles}
          renderOption={renderAccessRoleOption}
        />
      )}
      {/* Theme */}
      {form.getValues("userTheme") != null && (
        <RegrelloControlledFormFieldRadioGroup
          controllerProps={{
            control: form.control,
            name: "userTheme",
            rules: ValidationRules.REQUIRED,
          }}
          label={Theme}
          labelWidth={LABEL_WIDTH}
          options={THEME_RADIO_OPTIONS}
        />
      )}
      {/* Out of Office */}
      {!isOooSettingsHidden && (
        <div>
          <RegrelloFormFieldSwitch
            checked={outOfOffice != null}
            dataTestId={DataTestIds.USER_PROFILE_DIALOG_SWITCH}
            label={OutOfOffice}
            labelWidth={LABEL_WIDTH}
            onChange={onSwitchChange}
            secondaryLabel={SetAsAway}
          />
          <div className="ml-32">
            {outOfOffice != null &&
              triedToSubmitWithoutDelegates != null &&
              setTriedToSubmitWithoutDelegates != null && (
                <RegrelloOutOfOfficeEventForm
                  form={form}
                  setTriedToSubmitWithoutDelegates={setTriedToSubmitWithoutDelegates}
                  triedToSubmitWithoutDelegates={triedToSubmitWithoutDelegates}
                />
              )}
          </div>
        </div>
      )}
    </div>
  );
});

function renderAccessRoleOption(option: AccessRole) {
  return (
    <RegrelloTypography dataTestId={DataTestIds.FORM_FIELD_SELECT_OPTION} variant="body">
      {option.displayName}
    </RegrelloTypography>
  );
}

function getAccessRoleLabel(option: AccessRole) {
  return option.displayName;
}

function getAccessRoleValue(option: AccessRole) {
  return `${option.displayName}|${option.id}`;
}

function getAccessRoleIcon(option: RoleFields) {
  return option.isSystem ? ("custom-role-admin-field" as const) : ("role" as const);
}

function itemPredicate(query: string, item: RoleFields) {
  return item.name.toLocaleLowerCase().includes(query.toLocaleLowerCase());
}
